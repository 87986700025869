import React, {
  useLayoutEffect,
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import CanvasDraw from 'react-canvas-draw';
import { MdUndo } from 'react-icons/md';
import { FaEraser } from 'react-icons/fa';
import ReactConfirmAlert from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000;
  ${props =>
    (!props.enabledBrush || !props.enabled) &&
    `
      pointer-events: none;
    `}
  ${props =>
    !props.enabled &&
    `
      opacity: 0;
    `}

  * {
    font-family: 'system-ui', '-apple-system', 'BlinkMacSystemFont', '.SFNSText-Regular',
      'sans-serif' !important;
  }
`;

const ToolbarTitle = styled.h5`
  margin: 0 10px;
  font-weight: normal;
  color: #fff !important;

  @media (max-width: 600px) {
    visibility: hidden;
    width: 0px;
    height: 0px;
  }
`;

const BrushButton = styled.div`
  height: ${props => props.size * 2.5}px !important;
  width: ${props => props.size * 2.5}px !important;
  border-radius: 50%;
  border: 1px solid #fff !important;
  cursor: pointer;
  background: ${props => props.color};
  margin-right: 3px;
`;

const ButtonAction = styled.button`
  background: none !important;
  border: 0 !important;
  display: flex;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50%;
  padding: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem !important;
  color: white !important;

  &:focus {
    outline: 0;
  }
`;

const ButtonExit = styled(ButtonAction)`
  width: auto !important;;
  padding: 0 5px;
  border-radius: 0;
  font-size: 0.875rem !important;
  margin-left: 20px !important;
  color: #424242 !important;
  background: white !important;

  @media (max-width: 450px) {
    position: absolute;
    right: 10px;
  }
}
`;

const ToolbarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100001;
  background: #424242;
  border: 1px solid #444;
  padding: 5px 15px;
  pointer-events: auto !important;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${ToolbarTitle}:first-child {
    margin-left: 0;
  }

  ${BrushButton} + ${BrushButton}, ${ButtonAction} + ${ButtonAction} {
    margin-left: 2px;
  }
  
  @media (max-width: 600px) {
    padding-left: 0;
  }
  
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const CanvasContainer = styled.div`
  ${props =>
    props.enabled &&
    `
      cursor: crosshair;
    `}
  ${props =>
    !props.enabled &&
    `
      pointer-events: none;
    `}
`;

const CheckboxContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = _.debounce(() => {
      setSize([0, 0]);
      setTimeout(() => {
        setSize([
          window.document.documentElement.scrollWidth,
          window.document.documentElement.scrollHeight,
        ]);
      }, 500);
    }, 150);
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const CheckBox = ({ defaultChecked, onChange }) => {
  return (
    <CheckboxContainer>
      <input
        type="checkbox"
        defaultChecked={defaultChecked}
        onChange={e => onChange(e.target.checked)}
      />
      <span className="slider round" />
    </CheckboxContainer>
  );
};

CheckBox.propTypes = {
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckBox.defaultProps = {
  defaultChecked: false,
  onChange: () => {},
};

const BRUSH_SIZE_SMALL = 2;
const BRUSH_SIZE_MEDIUM = 4;
const BRUSH_SIZE_LARGE = 6;
const BRUSH_COLOR_RED = 'red';
const BRUSH_COLOR_BLACK = '#444';
const BRUSH_COLOR_WHITE = '#fff';
const BRUSH_COLOR_BLUE = 'blue';

const CanvasPaint = ({ defaultEnabled }, ref) => {
  const [windowWidth, windowHeight] = useWindowSize();
  const [isCanvasEnabled, enableCanvas] = useState(defaultEnabled);
  const [isBrushEnabled, enableBrush] = useState(true);
  const [isShowConfirmDialog, showConfirmDialog] = useState(false);
  const [brushSize, setBrushSize] = useState(BRUSH_SIZE_MEDIUM);
  const [brushColor, setBrushColor] = useState(BRUSH_COLOR_BLACK);
  const canvasRef = useRef();

  useImperativeHandle(ref, () => ({
    enable: (isEnabled = true) => {
      enableCanvas(isEnabled);
    },
    toggle: () => {
      enableCanvas(!isCanvasEnabled);
    },
  }));

  const setBrushSizeSmall = useCallback(() => {
    setBrushSize(BRUSH_SIZE_SMALL);
  }, []);
  const setBrushSizeMedium = useCallback(() => {
    setBrushSize(BRUSH_SIZE_MEDIUM);
  }, []);
  const setBrushSizeLarge = useCallback(() => {
    setBrushSize(BRUSH_SIZE_LARGE);
  }, []);

  const setBrushRed = useCallback(() => {
    setBrushColor(BRUSH_COLOR_RED);
  }, []);
  const setBrushBlack = useCallback(() => {
    setBrushColor(BRUSH_COLOR_BLACK);
  }, []);
  const setBrushWhite = useCallback(() => {
    setBrushColor(BRUSH_COLOR_WHITE);
  }, []);
  const setBrushBlue = useCallback(() => {
    setBrushColor(BRUSH_COLOR_BLUE);
  }, []);

  const undoCanvas = useCallback(() => {
    canvasRef.current.undo();
  }, []);
  const clearCanvas = useCallback(() => {
    showConfirmDialog(true);
  }, []);
  const disableCanvas = useCallback(() => {
    enableCanvas(false);
  }, []);

  const toggleBrush = useCallback(() => {
    enableBrush(!isBrushEnabled);
  }, [isBrushEnabled]);

  return (
    isCanvasEnabled && (
      <Container id="singleton-canvas" enabled={isCanvasEnabled} enabledBrush={isBrushEnabled}>
        <ToolbarContainer>
          <ToolbarTitle>Bút vẽ:</ToolbarTitle>
          <CheckBox defaultChecked={isBrushEnabled} onChange={toggleBrush} />
          <ToolbarTitle>Kích cỡ:</ToolbarTitle>
          <BrushButton onClick={setBrushSizeSmall} color={brushColor} size={BRUSH_SIZE_SMALL} />
          <BrushButton onClick={setBrushSizeMedium} color={brushColor} size={BRUSH_SIZE_MEDIUM} />
          <BrushButton onClick={setBrushSizeLarge} color={brushColor} size={BRUSH_SIZE_LARGE} />
          <ToolbarTitle>Màu sắc:</ToolbarTitle>
          <BrushButton onClick={setBrushBlack} color={BRUSH_COLOR_BLACK} size={BRUSH_SIZE_LARGE} />
          <BrushButton onClick={setBrushRed} color={BRUSH_COLOR_RED} size={BRUSH_SIZE_LARGE} />
          <BrushButton onClick={setBrushWhite} color={BRUSH_COLOR_WHITE} size={BRUSH_SIZE_LARGE} />
          <BrushButton onClick={setBrushBlue} color={BRUSH_COLOR_BLUE} size={BRUSH_SIZE_LARGE} />
          <ToolbarTitle>Chỉnh sửa:</ToolbarTitle>
          <ButtonAction onClick={undoCanvas}>
            <MdUndo />
          </ButtonAction>
          <ButtonAction onClick={clearCanvas}>
            <FaEraser />
          </ButtonAction>
          <ButtonExit onClick={disableCanvas}>Thoát</ButtonExit>
        </ToolbarContainer>
        <CanvasContainer enabled={isBrushEnabled}>
          <CanvasDraw
            ref={canvasRef}
            key={`${windowHeight}-${windowWidth}`}
            canvasWidth={windowWidth}
            canvasHeight={windowHeight}
            catenaryColor="rgba(0,0,0,0)"
            backgroundColor="rgba(0,0,0,0)"
            lazyRadius={2}
            brushRadius={brushSize}
            brushColor={isBrushEnabled ? brushColor : 'rgba(0,0,0,0)'}
            hideGrid
            disabled={!isBrushEnabled}
          />
          {isShowConfirmDialog && (
            <ReactConfirmAlert
              title="Bạn có muốn xoá hết tất cả nội dung đã vẽ?"
              buttons={[
                {
                  label: 'Có',
                  onClick: () => {
                    canvasRef.current.clear();
                    showConfirmDialog(false);
                  },
                },
                {
                  label: 'Không',
                  onClick: () => {
                    showConfirmDialog(false);
                  },
                },
              ]}
            />
          )}
        </CanvasContainer>
      </Container>
    )
  );
};

CanvasPaint.propTypes = {
  defaultEnabled: PropTypes.bool,
};

CanvasPaint.defaultProps = {
  defaultEnabled: false,
};

export default forwardRef(CanvasPaint);
