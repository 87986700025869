import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { darken } from 'polished';
import _ from 'lodash';
import moment from 'moment';
import ReactGA from 'react-ga';
import { DiscussionEmbed } from 'disqus-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FiEdit, FiInfo, FiYoutube, FiTrello, FiDownload, FiCheckSquare } from 'react-icons/fi';
import { MdPlaylistAdd, MdPlaylistAddCheck } from 'react-icons/md';
import 'video.js/dist/video-js.css';

import * as API from '../apis';
import breakpoints from '../styles/breakpoints';
import { slugify } from '../utils/slug';
import { ACTION, CATEGORY, LABEL } from '../constants/ga';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Video from '../components/Video';
import VideoPlayer from '../components/VideoPlayer';
import EndScreen from '../components/EndScreen';
import Delimiter from '../components/Delimiter';
import MutedText from '../components/MutedText';
import Markdown from '../components/Markdown';
import DescriptionList from '../components/DescriptionList';
import { Tabs, TabList, Tab, TabPanel } from '../components/Tabs';
import VideoPlaceholder from '../components/Placeholder/Video';
import Message from '../components/Message';
import TableOfContents from '../components/TableOfContents';
import CanvasPaint from '../components/CanvasPaint';
import { countViews } from '../utils/misc';
import { isAuthenticated, hasPermission } from '../utils/auth';
import GeoGebra from '../components/GeoGebra';
import QuestionTest from '../components/QuestionTest';
import { PERMISSIONS } from '../constants/permissions';

const NO_OF_NEXT = 3;
const NO_OF_FOR_YOU = 8;
const TIME_REG_EX = /^[0-9][0-9]:[0-9][0-9]:[0-9][0-9]$/;

const Wrapper = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.xl}) {
    flex-direction: column;
  }
`;

const MainContent = styled.section`
  flex: 1;
`;

const RightSidebar = styled.nav`
  width: 25rem;
  margin-left: 1.875rem;

  @media (max-width: ${breakpoints.xl}) {
    width: auto;
    margin-top: 2rem;
    margin-left: 0;
  }
`;

const Title = styled.h1`
  color: #2e2e2e;
  font-size: 1.375rem;
  font-weight: 500;
  margin: 1.5rem 0 0.5rem 0;
`;

const Subject = styled(MutedText)`
  font-size: 1.125rem;
`;

const ViewCount = styled(MutedText)`
  font-size: 1.125rem;
`;

const VideoInfo = styled.div`
  margin: 1.125rem 0;
`;

const SuggestionTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
`;

const SuggestionList = styled.div`
  & + & {
    margin-top: 2rem;
  }

  @media (max-width: ${breakpoints.xl}) {
    width: 60%;
  }

  @media (max-width: ${breakpoints.lg}) {
    width: 80%;
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

const ContentIcon = styled(FiEdit)`
  margin-right: 0.5rem;
`;

const InfoIcon = styled(FiInfo)`
  margin-right: 0.5rem;
`;

const GeoGebraIcon = styled(FiTrello)`
  margin-right: 0.5rem;
  vertical-align: text-bottom;
`;

const DownloadIcon = styled(FiDownload)`
  margin-right: 0.5rem;
  vertical-align: text-bottom;
`;

const GeoGebraTabIcon = styled(FiTrello)`
  margin-right: 0.5rem;
`;

const QuestionTestTabIcon = styled(FiCheckSquare)`
  margin-right: 0.5rem;
`;

const VideoIcon = styled(FiYoutube)`
  margin-right: 0.5rem;
  vertical-align: text-bottom;
`;

const DescriptionHeader = styled.h5`
  color: #2e2e2e;
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0.5rem 0;
`;

const ToolButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  margin: 0 0.375rem 0.5rem 1rem;
  border: 1px solid #b7b5bd;
  color: #78757a;
  font-size: 0.875rem;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:hover {
    border-color: ${darken(0.04, '#b7b5bd')};
    background-color: ${darken(0.04, '#ffffff')};
    color: ${darken(0.1, '#78757a')};
  }

  span {
    margin-left: 8px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: ${darken(0.08, '#ffffff')};
    color: ${darken(0.2, '#78757a')};
  }
`;

const VideoTitleContainer = styled.div`
  position: relative;
`;

const VideoButton = styled(ToolButton)`
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;

  ${props =>
    props.isFavorite &&
    `
    background: #10abe2;
    border-color: #10abe2;
    color: #ffffff;
  
    &:hover {
      background: ${darken(0.04, '#10abe2')};
      border-color: ${darken(0.04, '#10abe2')};
      color: #ffffff;
    }
  `}

  ${props =>
    props.isProcessing &&
    `
    pointer-events: none;
    cursor: default;
    background: ${darken(0.1, '#ffffff')};
    border-color: ${darken(0.1, '#b7b5bd')};
    color: ${darken(0.1, '#78757a')};
  `}
`;

const AddFavoriteIcon = styled(MdPlaylistAdd)`
  font-size: 0.875rem;
`;
const FavoriteIcon = styled(MdPlaylistAddCheck)`
  font-size: 0.875rem;
`;

const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 1.5rem;
  overflow: hidden;
`;

const GeoGebraWrapper = styled.div`
  margin-bottom: 1rem;

  ${props =>
    props.fixedHeight &&
    `
    height: 650px;

    @media (max-width: ${breakpoints.xl}) {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  `}
`;

const VideoWrapper = styled.div`
  @media (max-width: ${breakpoints.xl}) {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;

function SingleVideo({ data, location }) {
  const { trangVideo } = data;
  const [currentVideo, setCurrentVideo] = useState(trangVideo);
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState('');
  const [isFavorite, setFavorite] = useState(null);
  const [isFavoriteProcessing, setFavoriteProcessing] = useState(false);
  const [hasDownloadUrl, setHasDownloadUrl] = useState(null);
  const [obtainedSubscription, setObtainedSubscription] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState();
  const [downloadCaptcha, setDownloadCaptcha] = useState();
  const [fetchingDownloadUrl, setFetchingDownloadUrl] = useState(false);
  const [questions, setQuestions] = useState(null);
  const videoComp = useRef();
  const canvasComp = useRef();

  const disqusConfig = {
    url: location.href,
    identifier: currentVideo.slug,
    title: currentVideo.name,
  };

  const videoSource = currentVideo.source || currentVideo.demoSource;
  const upNextVideos = relatedVideos.slice(0, NO_OF_NEXT);
  const forYouVideos = relatedVideos.slice(NO_OF_NEXT, NO_OF_FOR_YOU);

  const parseResponseVideo = video => {
    return {
      ...video,
      tableOfContents: _.reduce(
        video.tableOfContents,
        (tableOfContents, content) => {
          if (!content) {
            return tableOfContents;
          }
          const [time, title] = content.split('|');
          if (!TIME_REG_EX.test(time) || !title) {
            return tableOfContents;
          }

          tableOfContents.push({
            time,
            title,
          });
          return tableOfContents;
        },
        []
      ),
    };
  };

  useEffect(() => {
    async function fetchVideo() {
      try {
        setLoading(true);
        setFavorite(null);

        const response = await API.getVideo(trangVideo.rawID);

        try {
          if (isAuthenticated()) {
            setFavorite(await API.checkFavoriteVideo(trangVideo.rawID));
          }
        } catch (ignored) {
          // do nothing
        }

        setLoading(false);
        setCurrentVideo(parseResponseVideo(response.video));
        setRelatedVideos(response.relatedVideos || []);
        setObtainedSubscription(
          (hasPermission(PERMISSIONS.VIEW_SUBSCRIPTION_VIDEOS) && response.obtainedSubscription) ||
            hasPermission(PERMISSIONS.VIEW_ALL_VIDEOS)
        );

        const { video } = response;

        if (video.source) {
          ReactGA.event({
            category: CATEGORY.VIDEO,
            action: ACTION.VIEW_PURCHASED_VIDEO,
          });
        } else if (video.demoSource) {
          ReactGA.event({
            category: CATEGORY.VIDEO,
            action: ACTION.VIEW_DEMO_VIDEO,
          });
        }
        if (response.video.contentType !== 'video') {
          API.markViewed(trangVideo.rawID);
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message) {
          setLoadingError(error.response.data.message);
        } else {
          setLoadingError('Có lỗi xảy ra khi tải video. Vui lòng thử lại sau!');
        }
        console.error(error);
      }
    }

    fetchVideo();
  }, [trangVideo]);

  useEffect(() => {
    async function getQuestions() {
      try {
        const response = await API.getVideoQuestions(trangVideo.rawID);
        setQuestions(response);
      } catch (error) {
        console.error(error);
      }
    }

    getQuestions();
  }, [trangVideo]);

  useEffect(() => {
    async function checkDownloadUrl() {
      try {
        const response = await API.checkDownloadUrl(trangVideo.rawID);
        setHasDownloadUrl(response);
      } catch (error) {
        console.error(error);
      }
    }

    checkDownloadUrl();
  }, [trangVideo]);

  const suggestionsList = useMemo(
    () => (
      <>
        <SuggestionList>
          <SuggestionTitle>Kế tiếp</SuggestionTitle>

          <Delimiter />

          {loading && <VideoPlaceholder layout="vertical" />}
          {!loading && !upNextVideos.length && <MutedText>Không có video kế tiếp</MutedText>}

          {upNextVideos.slice(0, NO_OF_NEXT).map(video => (
            <Video
              key={video.code}
              title={video.name}
              subtitle={video.subject && video.subject.name}
              thumbnail={video.thumbnail}
              link={`videos/${slugify(video.name)}`}
              duration={video.duration}
              viewCount={countViews(video.views, video.demoViews)}
              publishDate={video.publishDate}
              layout="vertical"
              gaLabel={LABEL.VIDEO_PAGE_NEXT_VIDEOS}
            />
          ))}
        </SuggestionList>

        <SuggestionList>
          <SuggestionTitle>Gợi ý cho bạn</SuggestionTitle>

          <Delimiter />

          {loading && <VideoPlaceholder layout="vertical" />}
          {!loading && !forYouVideos.length && <MutedText>Không có video gợi ý</MutedText>}

          {forYouVideos.map(video => (
            <Video
              key={video.code}
              title={video.name}
              subtitle={video.subject && video.subject.name}
              thumbnail={video.thumbnail}
              link={`videos/${slugify(video.name)}`}
              duration={video.duration}
              viewCount={countViews(video.views, video.demoViews)}
              publishDate={video.publishDate}
              layout="vertical"
              gaLabel={LABEL.VIDEO_PAGE_RELATED_VIDEOS}
            />
          ))}
        </SuggestionList>
      </>
    ),
    [trangVideo, forYouVideos, loading, upNextVideos]
  );

  const handlePlaying = () => {
    if (currentVideo.source) {
      API.markViewed(trangVideo.rawID);
    } else {
      API.markDemoViewed(trangVideo.rawID);
    }
  };

  const getDownloadUrl = async () => {
    setFetchingDownloadUrl(true);
    try {
      const response = await API.getDownloadUrl({
        videoCode: trangVideo.rawID,
        captcha: downloadCaptcha,
      });
      setDownloadUrl(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSeeking = ({ time }) => {
    if (videoComp.current) {
      const [hour = 0, min = 0, sec = 0] = time.split(':');
      videoComp.current.seek(+hour * 60 * 60 + +min * 60 + +sec);

      ReactGA.event({
        category: CATEGORY.VIDEO,
        action: ACTION.USE_TABLE_OF_CONTENTS,
      });
    }
  };

  const toggleFavorite = _.debounce(async () => {
    if (isFavorite === true) {
      setFavoriteProcessing(true);
      if (await API.removeFavoriteVideo(currentVideo.code)) {
        setFavorite(false);
      }

      setFavoriteProcessing(false);
      ReactGA.event({
        category: CATEGORY.VIDEO,
        action: ACTION.REMOVE_FAVORITE_VIDEO,
      });
    } else if (isFavorite === false) {
      setFavoriteProcessing(true);
      if (await API.addFavoriteVideo(currentVideo.code)) {
        setFavorite(true);
      }

      setFavoriteProcessing(false);
      ReactGA.event({
        category: CATEGORY.VIDEO,
        action: ACTION.ADD_FAVORITE_VIDEO,
      });
    }
  }, 250);

  const { tableOfContents, geoGebraFile, geoGebraType } = currentVideo;
  const hasTableOfContents = tableOfContents && tableOfContents.length > 0;
  const hasEmbededGeoGebra = currentVideo.contentType === 'mixed' && geoGebraFile && geoGebraType;

  return (
    <>
      <Layout>
        <SEO
          title={currentVideo.name}
          meta={[
            {
              name: 'keywords',
              content: trangVideo.tags ? trangVideo.tags.join(',') : '',
            },
          ]}
        />
        <Wrapper style={{ flexDirection: 'column' }}>
          {currentVideo.contentType === 'geogebra' && geoGebraFile && geoGebraType && (
            <GeoGebraWrapper fixedHeight={obtainedSubscription}>
              <GeoGebra
                url={geoGebraFile.url}
                type={geoGebraType}
                needSubscription={!obtainedSubscription}
                height={650}
                link="/purchase-subscription"
              />
            </GeoGebraWrapper>
          )}
          <Wrapper>
            <MainContent>
              {currentVideo.contentType !== 'geogebra' && (
                <>
                  {videoSource ? (
                    <VideoWrapper>
                      <VideoPlayer
                        controls
                        html5={{
                          hls: {
                            overrideNative: true,
                          },
                        }}
                        autoplay={false}
                        aspectRatio="16:9"
                        sources={[
                          {
                            src: videoSource,
                            type: 'application/x-mpegURL',
                          },
                        ]}
                        poster={currentVideo.thumbnail}
                        endScreen={!currentVideo.source ? <EndScreen /> : null}
                        onPlaying={handlePlaying}
                        tableOfContents={tableOfContents}
                        ref={videoComp}
                      />
                    </VideoWrapper>
                  ) : (
                    <Video
                      thumbnail={currentVideo.thumbnail}
                      duration={currentVideo.duration}
                      link={!loading ? '/purchase-subscription' : ''}
                      loading={loading}
                      thumbnailOnly
                      gaAction={ACTION.GO_TO_PURCHASE}
                      gaLabel={LABEL.VIDEO_PAGE}
                    />
                  )}
                </>
              )}

              {loadingError && <Message error>{loadingError}</Message>}

              <Title>
                {currentVideo.contentType === 'geogebra' ? <GeoGebraIcon /> : <VideoIcon />}
                {currentVideo.name}
              </Title>
              <VideoTitleContainer>
                <Subject>{currentVideo.subject && currentVideo.subject.name}</Subject>
                <ViewCount>
                  {`${countViews(currentVideo.views, currentVideo.demoViews)} lượt xem`}
                </ViewCount>
                {isFavorite !== null && (
                  <VideoButton
                    isFavorite={isFavorite}
                    isProcessing={isFavoriteProcessing}
                    onClick={toggleFavorite}
                  >
                    {!isFavorite && (
                      <>
                        <AddFavoriteIcon />
                        <span>Thêm vào video của tôi</span>
                      </>
                    )}
                    {isFavorite && (
                      <>
                        <FavoriteIcon />
                        <span>Video của tôi</span>
                      </>
                    )}
                  </VideoButton>
                )}
              </VideoTitleContainer>

              <VideoInfo>
                <Tabs defaultIndex={0}>
                  <TabList>
                    <Tab>
                      <ContentIcon />
                      Nội dung
                    </Tab>
                    <Tab>
                      <InfoIcon />
                      Thông tin
                    </Tab>
                    {hasEmbededGeoGebra && (
                      <Tab>
                        <GeoGebraTabIcon />
                        Công cụ tương tác
                      </Tab>
                    )}
                    {questions && questions.length > 0 && (
                      <Tab>
                        <QuestionTestTabIcon />
                        Trắc nghiệm
                      </Tab>
                    )}
                    {hasDownloadUrl !== null && (
                      <Tab>
                        <DownloadIcon />
                        Tải về
                      </Tab>
                    )}
                  </TabList>

                  <TabPanel>
                    {hasTableOfContents && (
                      <>
                        <DescriptionHeader>Mục lục:</DescriptionHeader>
                        <TableOfContents
                          contents={tableOfContents}
                          onSelect={handleSeeking}
                          selectable={!!currentVideo.source}
                        />
                        <br />
                      </>
                    )}
                    {hasTableOfContents && <DescriptionHeader>Mô tả:</DescriptionHeader>}
                    {currentVideo.description && currentVideo.description.md ? (
                      <Markdown source={currentVideo.description.md} />
                    ) : (
                      <MutedText>Chưa có mô tả</MutedText>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <DescriptionList>
                      <DescriptionList.Item>
                        <em>Mã: </em>
                        {currentVideo.code}
                      </DescriptionList.Item>
                      <DescriptionList.Item>
                        <em>Phiên bản: </em>
                        {currentVideo.version}
                      </DescriptionList.Item>
                      <DescriptionList.Item>
                        <em>Môn học: </em>
                        {currentVideo.subject && currentVideo.subject.name}
                      </DescriptionList.Item>
                      <DescriptionList.Item>
                        <em>Thể loại: </em>
                        {currentVideo.genre && currentVideo.genre.join(', ')}
                      </DescriptionList.Item>
                      <DescriptionList.Item>
                        <em>Thời lượng: </em>
                        {currentVideo.duration}
                      </DescriptionList.Item>
                      <DescriptionList.Item>
                        <em>Định dạng: </em>
                        {currentVideo.format}
                      </DescriptionList.Item>
                      <DescriptionList.Item>
                        <em>Ngôn ngữ: </em>
                        {currentVideo.language}
                      </DescriptionList.Item>
                      <DescriptionList.Item>
                        <em>Tác giả: </em>
                        {currentVideo.copyright}
                      </DescriptionList.Item>
                      <DescriptionList.Item>
                        <em>Năm xuất bản: </em>
                        {moment(currentVideo.publishDate).format('DD/MM/YYYY')}
                      </DescriptionList.Item>
                      <DescriptionList.Item>
                        <em>Quyền chia sẻ: </em>
                        {currentVideo.sharingPermission}
                      </DescriptionList.Item>
                      <DescriptionList.Item>
                        <em>Gói học: </em>
                        {currentVideo.subscriptions
                          ? currentVideo.subscriptions
                              .map(subscription => subscription.name)
                              .join(', ')
                          : 'Không có thông tin'}
                      </DescriptionList.Item>
                    </DescriptionList>
                  </TabPanel>
                  {hasEmbededGeoGebra && (
                    <TabPanel>
                      <GeoGebra
                        url={geoGebraFile.url}
                        type={geoGebraType}
                        needSubscription={!obtainedSubscription}
                        link="/purchase-subscription"
                      />
                    </TabPanel>
                  )}
                  {questions && questions.length > 0 && (
                    <TabPanel>
                      <br />
                      <QuestionTest questions={questions} />
                    </TabPanel>
                  )}
                  {hasDownloadUrl !== null && (
                    <TabPanel>
                      {hasDownloadUrl === true && (
                        <>
                          <span>
                            Video tải xuống được sử dụng với mục đích phi thương mại và không dược
                            phát tán lên các trang mạng xã hội
                          </span>
                          <CaptchaWrapper>
                            <ReCAPTCHA
                              sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY}
                              onChange={setDownloadCaptcha}
                            />
                          </CaptchaWrapper>
                          <center>
                            <br />
                            {!downloadUrl && (
                              <ToolButton
                                disabled={!downloadCaptcha && !fetchingDownloadUrl}
                                onClick={getDownloadUrl}
                              >
                                Lấy link tải về
                              </ToolButton>
                            )}
                            {downloadUrl && (
                              // eslint-disable-next-line react/jsx-no-target-blank
                              <a href={downloadUrl} target="_blank">
                                <ToolButton disabled={!downloadCaptcha}>Tải về</ToolButton>
                              </a>
                            )}
                          </center>
                        </>
                      )}
                      {hasDownloadUrl === false && (
                        <>
                          <span>Bạn cần mua gói 12 tháng để tải video. </span>
                          <Link to="/purchase-subscription">Mua ngay</Link>
                        </>
                      )}
                    </TabPanel>
                  )}
                </Tabs>
              </VideoInfo>

              <DiscussionEmbed
                shortname={process.env.GATSBY_DISQUS_SHORTNAME}
                config={disqusConfig}
              />
            </MainContent>

            <RightSidebar>{suggestionsList}</RightSidebar>
          </Wrapper>
        </Wrapper>
      </Layout>
      <CanvasPaint defaultEnabled={false} ref={canvasComp} />
    </>
  );
}

SingleVideo.propTypes = {
  data: PropTypes.shape({
    trangVideo: PropTypes.shape({
      rawID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
};

export default SingleVideo;

export const pageQuery = graphql`
  query($slug: String!) {
    trangVideo(slug: { eq: $slug }) {
      ...VideoFragment
    }
  }
`;
