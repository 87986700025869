import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import breakpoints from '../../styles/breakpoints';
import Button from '../Button';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.6);
  font-size: 2rem;
  padding: 1rem;
`;

const Info = styled.div`
  font-size: 1.125rem;
  text-align: center;
  padding: 0 2.5rem 2rem 2.5rem;

  @media (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
    padding: 0 1rem 1rem 1rem;
  }
`;

function EndScreen() {
  return (
    <Wrapper>
      <Info>Để có thể xem đầy đủ nội dung video xin vui lòng đăng ký mua gói</Info>
      <Button onClick={() => navigate('/purchase-subscription')}>Đến trang mua gói</Button>
    </Wrapper>
  );
}

export default EndScreen;
