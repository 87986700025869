import React, { useRef, useLayoutEffect, useState, useMemo, useCallback } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import usePortal from 'react-useportal';
import { FaPencilAlt, FaExpand, FaCompress } from 'react-icons/fa';
import FullScreen from 'react-full-screen';
import _ from 'lodash';

import NotFoundPic from '../../images/not-found.jpg';
import MutedText from '../MutedText';
import CanvasPaint from '../CanvasPaint';

const NoSubscriptionImg = styled.img`
  max-height: 650px;
  width: 100%;
  object-fit: cover;
`;

const Wrapper = styled.div`
  position: relative;
`;

const ToolbarWrapper = styled.div`
  height: 36px;
  background: #424242;
  text-align: right;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.div`
  width: 36px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  line-height: 36px;
  text-align: center;
  cursor: pointer;

  :hover {
    background: #656565;
  }
`;

function GeoGebra({ url, type, height, needSubscription, link }) {
  const id = useMemo(() => `ggb-${Date.now()}`, [url]);
  const { Portal } = usePortal();
  const wrapperRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [widthNotFullScreen, setWidthNotFullScreen] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);
  const canvas = useRef();
  const fullScreenCanvas = useRef();

  const showCanvas = useCallback(() => {
    if (!fullscreen && canvas && canvas.current) {
      canvas.current.enable();
    }
    if (fullscreen && fullScreenCanvas && fullScreenCanvas.current) {
      fullScreenCanvas.current.enable();
    }
  }, [canvas, fullScreenCanvas, fullscreen]);

  const toggleFullScreen = useCallback(() => {
    setFullscreen(!fullscreen);
  }, [fullscreen]);

  useLayoutEffect(() => {
    const updateSize = _.debounce(() => {
      const wrapperWidth = wrapperRef.current ? wrapperRef.current.offsetWidth : 0;
      if (!fullscreen) {
        setWidth(width !== 0 ? widthNotFullScreen : wrapperWidth);
        setWidthNotFullScreen(wrapperWidth);
      } else {
        setWidth(wrapperWidth);
      }
    }, 500);
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [wrapperRef, fullscreen]);

  return (
    <FullScreen
      enabled={fullscreen}
      onChange={isFullscreen => !isFullscreen && setFullscreen(false)}
    >
      <Wrapper ref={wrapperRef}>
        {width !== 0 && (
          <>
            {needSubscription && (
              <>
                <NoSubscriptionImg src={NotFoundPic} alt="Không có gói cước" />
                <MutedText>Bạn chưa có gói cước để xem nội dung này.</MutedText>
                <Link to={link}>Mua ngay</Link>
              </>
            )}
            {!needSubscription && (
              <>
                <div id={id} style={{ width }} />
                <Helmet>
                  <script type="text/javascript">
                    {`
                var ggbApp = new GGBApplet({
                  appName: '${type}',
                  filename: '${url}',
                  width: ${width},
                  height: ${fullscreen ? window.outerHeight - 36 : height - 36},
                  autoHeight: true,
                  showToolBar: false,
                  showAlgebraInput: false,
                  showMenuBar: false,
                  showZoomButtons: true,
                  perspective: '${type === '3d' ? 'T' : 'G'}',
                  enableShiftDragZoom: true,
                },
                true
                );
                ggbApp.setHTML5Codebase('/GeoGebra/HTML5/5.0/web3d/');
                ggbApp.inject('${id}');
              `}
                  </script>
                </Helmet>
              </>
            )}
          </>
        )}
        {!needSubscription && (
          <>
            <ToolbarWrapper>
              <Button onClick={toggleFullScreen}>
                {fullscreen && <FaCompress />}
                {!fullscreen && <FaExpand />}
              </Button>
              <Button onClick={showCanvas}>
                <FaPencilAlt />
              </Button>
            </ToolbarWrapper>
            <Portal>
              <CanvasPaint defaultEnabled={false} ref={canvas} />
            </Portal>
          </>
        )}
      </Wrapper>
      <CanvasPaint key={`${height}-${width}`} defaultEnabled={false} ref={fullScreenCanvas} />
    </FullScreen>
  );
}

GeoGebra.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['classic', 'graphing', 'geometry', '3d']).isRequired,
  height: PropTypes.number,
  needSubscription: PropTypes.bool,
  link: PropTypes.string,
};

GeoGebra.defaultProps = {
  height: 800,
  needSubscription: true,
  link: null,
};

export default GeoGebra;
