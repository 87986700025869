import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useImperativeHandle,
  useCallback,
  forwardRef,
} from 'react';
import usePortal from 'react-useportal';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FiX } from 'react-icons/fi';
import VideoJS from 'video.js';

import breakpoints from '../../styles/breakpoints';
import CanvasPaint from '../CanvasPaint';
import '../../styles/font-awesome.min.css';
import TableOfContents from '../TableOfContents';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: ${({ minHeight }) => minHeight}px;
  background: #000000;
  margin-bottom: 4rem;

  .fullscreen-enabled {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  video {
    outline: none;
  }

  .video-js .vjs-control-bar {
    position: unset;
    display: flex;
    background: #424242;
    height: 36px;
  }

  .video-js .vjs-control-bar .vjs-icon-placeholder .fa {
    font-size: 1rem;
  }

  .video-js.vjs-fullscreen .vjs-control-bar {
    position: absolute;
    display: flex;
    background: #000000;
  }

  .video-js .vjs-control-bar .vjs-button {
    font-size: 0.75rem;
  }

  .video-js .vjs-slider {
    background-color: rgba(255, 255, 255, 0.35);
  }

  .video-js .vjs-load-progress div {
    background: rgba(255, 255, 255, 0.75);
  }

  .video-js .vjs-remaining-time {
    font-size: 1.35em !important;
    line-height: 2.75em;
  }

  .video-js .vjs-big-play-button {
    left: 50%;
    margin-left: -72px;
    top: 50%;
    margin-top: -39px;
    font-size: 3rem;
    background-color: rgba(14, 171, 226, 0.6);
  }

  .video-js .vjs-control.vjs-button {
    cursor: pointer;
  }
`;

const PlayerWrapper = styled.div``;

const EndScreenWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
`;

const CloseIcon = styled(FiX)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #ffffff;
  font-size: 2rem;
  cursor: pointer;

  @media (max-width: ${breakpoints.sm}) {
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.5rem;
  }
`;

const Video = styled.video.attrs({ className: 'video-js' })``;

const TableOfContentsBackdrop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: ${({ fullscreen }) => (fullscreen ? 0 : -36)}px;
`;

const TableOfContentsWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 36px;
  background: #424242;
  padding: 0.75rem 1rem;
  border: 1px solid #333333;
  color: #fff;
  font-size: 1rem;
  line-height: 1.375rem;
`;

const CustomPortal = ({ el, children }) => {
  const { Portal } = usePortal({ bindTo: el });
  return <Portal>{children}</Portal>;
};

CustomPortal.propTypes = {
  el: PropTypes.shape({}),
  children: PropTypes.node,
};

CustomPortal.defaultProps = {
  el: undefined,
  children: undefined,
};

const VideoControlBarButtonHTML = (icon, hint) => `
      <span aria-hidden="true" class="vjs-icon-placeholder">
          <i class="fa ${icon}" aria-hidden="true" ></i>
      </span>
      <span class="vjs-control-text" aria-live="polite">${hint}</span>
`;

function VideoPlayer({ className, endScreen, onPlaying, tableOfContents, ...videoProps }, ref) {
  const [minHeight, setMinHeight] = useState(0);
  const [showEndScreen, setShowEndScreen] = useState(false);
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const wrapper = useRef();
  const videoNode = useRef();
  const canvas = useRef();
  const fullScreenCanvas = useRef();
  const { Portal } = usePortal();
  let player;

  useLayoutEffect(() => {
    if (videoProps.aspectRatio) {
      const [scaleWidth, scaleHeight] = videoProps.aspectRatio.split(':');
      setMinHeight((wrapper.current.offsetWidth * scaleHeight) / scaleWidth);
    }
  }, []);

  const handleSeeking = useCallback(({ time }) => {
    if (player) {
      const [hour = 0, min = 0, sec = 0] = time.split(':');
      player.currentTime(+hour * 60 * 60 + +min * 60 + +sec);
      if (player.paused()) {
        player.play();
      }
    }
  }, []);

  const hideTableOfContents = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();

    setShowTableOfContents(false);
  }, []);

  const isInFullScreen = () => {
    const { document } = window;
    return (
      (document.fullscreenElement && true) ||
      (document.webkitFullscreenElement && true) ||
      (document.mozFullScreenElement && true) ||
      (document.msFullscreenElement && true)
    );
  };

  useEffect(() => {
    const Button = VideoJS.getComponent('Button');

    const DrawButton = VideoJS.extend(Button, {
      handleClick() {
        if (isInFullScreen()) {
          if (fullScreenCanvas && fullScreenCanvas.current) {
            fullScreenCanvas.current.toggle();
          }
        } else if (canvas && canvas.current) {
          canvas.current.toggle();
        }
      },
    });

    const TableOfContentButton = VideoJS.extend(Button, {
      handleClick() {
        setShowTableOfContents(true);
      },
    });

    VideoJS.registerComponent('DrawButton', DrawButton);
    VideoJS.registerComponent('TableOfContentButton', TableOfContentButton);

    player = VideoJS(
      videoNode.current,
      {
        ...videoProps,
        inactivityTimeout: 0,
      },
      function onPlayerReady() {
        if (onPlaying) {
          this.on('playing', onPlaying);
        }
        this.on('ended', () => {
          setShowEndScreen(true);
          this.exitFullscreen();
        });
        setMinHeight(0);
        console.debug('onPlayerReady', this);
      }
    );

    const fullscreenHandler = () => {
      if (fullScreenCanvas && fullScreenCanvas.current) {
        fullScreenCanvas.current.enable(false);
      }
    };

    document.addEventListener('fullscreenchange', fullscreenHandler);

    const drawButton = player.getChild('controlBar').addChild('drawButton', {});
    drawButton.el().innerHTML = VideoControlBarButtonHTML('fa-pencil', 'Bút vẽ');

    if (tableOfContents && tableOfContents.length > 0) {
      const tableOfContentButton = player
        .getChild('controlBar')
        .addChild('tableOfContentButton', {});
      tableOfContentButton.el().innerHTML = VideoControlBarButtonHTML('fa-list-ul', 'Mục lục');
    }

    return () => {
      if (player) {
        player.dispose();
      }

      document.removeEventListener('fullscreenchange', fullscreenHandler);
    };
  }, [tableOfContents]);

  useImperativeHandle(
    ref,
    () => ({
      seek: time => {
        if (player) {
          player.currentTime(time);
          if (player.paused()) {
            player.play();
          }
        }
      },
    }),
    []
  );

  return (
    <Wrapper className={className} minHeight={minHeight} ref={wrapper}>
      <PlayerWrapper data-vjs-player>
        <Video ref={videoNode} />
      </PlayerWrapper>
      {endScreen && showEndScreen && (
        <EndScreenWrapper>
          <CloseIcon onClick={() => setShowEndScreen(false)} />
          {endScreen}
        </EndScreenWrapper>
      )}
      {showTableOfContents && (
        <CustomPortal el={videoNode && videoNode.current && videoNode.current.parentElement}>
          <TableOfContentsBackdrop onClick={hideTableOfContents} fullscreen={isInFullScreen()}>
            <TableOfContentsWrapper>
              <TableOfContents contents={tableOfContents} onSelect={handleSeeking} selectable />
            </TableOfContentsWrapper>
          </TableOfContentsBackdrop>
        </CustomPortal>
      )}
      <Portal>
        <CanvasPaint defaultEnabled={false} ref={canvas} />
      </Portal>
      <CustomPortal el={videoNode && videoNode.current && videoNode.current.parentElement}>
        <CanvasPaint defaultEnabled={false} ref={fullScreenCanvas} />
      </CustomPortal>
    </Wrapper>
  );
}

VideoPlayer.propTypes = {
  className: PropTypes.string,
  endScreen: PropTypes.node,
  onPlaying: PropTypes.func,
  tableOfContents: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

VideoPlayer.defaultProps = {
  className: '',
  endScreen: null,
  onPlaying: null,
  tableOfContents: [],
};

export default forwardRef(VideoPlayer);
