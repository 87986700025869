import styled from 'styled-components';

const DescriptionList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

DescriptionList.Item = styled.li`
  em {
    font-style: normal;
    font-weight: 500;
  }
`;

export default DescriptionList;
