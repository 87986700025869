import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import _ from 'lodash';

import { refineHTMLContent } from '../../utils/browser';
import Checkbox from '../Checkbox';
import Button from '../Button';
import colors from '../../styles/colors';

const Wrapper = styled.div``;

const QuestionDescription = styled.div`
  img {
    max-width: 500px !important;
  }

  @media (max-width: 500px) {
    img {
      max-width: 100% !important;
    }
  }
`;

const Title = styled.h4``;

const AnswerList = styled.div`
  margin-top: 0.5rem;
`;

const AnswerSelect = styled.div`
  margin-bottom: 0.25rem;
  padding: 0.25rem;
  pointer-events: none;

  ${props =>
    props.selectable &&
    `
    cursor: pointer;  
    pointer-events: initial;  
  `}

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }

  ${({ showResult, checked, correct }) => {
    if (showResult) {
      if (correct) {
        return `background: ${colors.green[10]};`;
      }

      if (checked) {
        return `background: ${colors.red[10]};`;
      }
    }

    return ``;
  }}
`;

const AnswerCheckbox = styled(Checkbox).attrs(() => ({}))`
  display: inline;
  margin-right: 10px;
  pointer-event: none;

  > div {
    vertical-align: text-bottom;
  }

  ${props =>
    props.showResult &&
    `
      svg {
        stroke: ${props.correct ? colors.green[50] : colors.red[50]};
      }
      input:checked + div {
        border-color: ${props.correct ? colors.green[50] : colors.red[50]} !important;
      }
  `}
`;

const Answer = styled.div`
  display: inline;
`;

const ProgressStatus = styled.div`
  display: inline-block;
  float: right;
`;

const AnswerResult = styled.div`
  margin-top: 15px;
  font-weight: bold;
`;

const CorrectAnswer = styled(AnswerResult)`
  color: ${colors.green[50]};
`;

const WrongAnswer = styled(AnswerResult)`
  color: ${colors.red[50]};
`;

const ChartWrapper = styled.div`
  max-width: 300px;
  margin: 0 0 2rem;
  text-align: center;
`;

const TEST_STATE = {
  ANSWERING: 'ANSWERING',
  ANSWER_DONE: 'QUESTION_DONE',
  FINISHED: 'FINISHED',
  VIEW_RESULT: 'VIEW_RESULT',
};

function QuestionTest({ questions }) {
  const [randomQuestions, setRandomQuestions] = useState(_.shuffle(questions));
  const [resultAnswer, setResultAnswer] = useState({
    correct: 0,
    wrong: 0,
  });
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [questionNumber, setQuestionNumber] = useState(0);
  const [testState, setTestState] = useState(TEST_STATE.ANSWERING);

  const checkCorrectAnswers = (_answers, _selectedAnswer) => {
    const correctAnswers = _.filter(_answers, a => a.correct);
    const selectedAnswersArr = _.values(_selectedAnswer);
    return (
      correctAnswers.length === selectedAnswersArr.length &&
      _.every(selectedAnswersArr, a => a.correct)
    );
  };

  const question = useMemo(() => randomQuestions[questionNumber], [
    randomQuestions,
    questionNumber,
  ]);

  const isMultipleAnswers = useMemo(() => {
    if (
      !randomQuestions ||
      !randomQuestions[questionNumber] ||
      !randomQuestions[questionNumber].correctAnswers
    ) {
      return false;
    }

    return randomQuestions[questionNumber].correctAnswers.length > 1;
  }, [randomQuestions, questionNumber]);

  const selectAnswer = useCallback(
    (answer, e) => {
      e.stopPropagation();
      e.preventDefault();

      if (testState !== TEST_STATE.ANSWERING) {
        return;
      }

      if (!isMultipleAnswers) {
        setSelectedAnswer({
          [answer.id]: answer,
        });
      } else if (_.has(selectedAnswer, answer.id)) {
        setSelectedAnswer(_.omit(selectedAnswer, answer.id));
      } else {
        setSelectedAnswer({
          ...selectedAnswer,
          [answer.id]: answer,
        });
      }
    },
    [questionNumber, randomQuestions, selectedAnswer, testState]
  );

  const answers = useMemo(
    () =>
      question
        ? _.shuffle([
            ..._.map(question.correctAnswers, a => ({
              content: a,
              correct: true,
              id: Math.random(),
            })),
            ..._.map(question.wrongAnswers, a => ({
              content: a,
              correct: false,
              id: Math.random(),
            })),
          ])
        : [],
    [question, questionNumber]
  );

  const answerQuestion = useCallback(() => {
    setTestState(TEST_STATE.ANSWER_DONE);
    if (checkCorrectAnswers(answers, selectedAnswer)) {
      setResultAnswer({
        ...resultAnswer,
        correct: resultAnswer.correct + 1,
      });
    } else {
      setResultAnswer({
        ...resultAnswer,
        wrong: resultAnswer.wrong + 1,
      });
    }
  }, [questionNumber, answers, selectedAnswer, resultAnswer]);

  const nextQuestion = useCallback(() => {
    setQuestionNumber(questionNumber < questions.length - 1 ? questionNumber + 1 : -1);
    setSelectedAnswer({});
    setTestState(TEST_STATE.ANSWERING);
  }, [questionNumber]);

  const viewResult = useCallback(() => {
    setTestState(TEST_STATE.VIEW_RESULT);
  }, [questionNumber]);

  const reTest = useCallback(() => {
    setQuestionNumber(0);
    setRandomQuestions(_.shuffle(questions));
    setTestState(TEST_STATE.ANSWERING);
    setResultAnswer({ correct: 0, wrong: 0 });
  }, [questionNumber]);

  const correctResult = useMemo(() => {
    return checkCorrectAnswers(answers, selectedAnswer);
  }, [answers, selectedAnswer]);

  if (!questions || questions.length === 0) {
    return <p>Chưa có câu hỏi nào.</p>;
  }

  return (
    <Wrapper>
      {testState !== TEST_STATE.VIEW_RESULT && (
        <>
          <Title>{`Câu ${questionNumber + 1}: ${question.question}`}</Title>
          {question && question.description && (
            <QuestionDescription
              dangerouslySetInnerHTML={{ __html: refineHTMLContent(question.description) }}
            />
          )}
          {!isMultipleAnswers && <Answer>Chọn một đáp án:</Answer>}
          {isMultipleAnswers && <Answer>Chọn nhiều đáp án:</Answer>}
          <AnswerList disabled={testState !== TEST_STATE.ANSWERING}>
            {_.map(answers, answer => (
              <AnswerSelect
                key={answer.id}
                onClick={_.debounce(e => selectAnswer(answer, e), 100)}
                selectable={testState === TEST_STATE.ANSWERING}
                correct={answer.correct}
                showResult={testState === TEST_STATE.ANSWER_DONE}
                checked={selectedAnswer[answer.id]}
              >
                <AnswerCheckbox
                  checked={selectedAnswer[answer.id]}
                  correct={answer.correct}
                  showResult={testState === TEST_STATE.ANSWER_DONE}
                />
                <Answer>{answer.content}</Answer>
              </AnswerSelect>
            ))}
          </AnswerList>
        </>
      )}
      {testState === TEST_STATE.ANSWER_DONE && correctResult && <CorrectAnswer>ĐÚNG</CorrectAnswer>}
      {testState === TEST_STATE.ANSWER_DONE && !correctResult && <WrongAnswer>SAI</WrongAnswer>}
      {testState === TEST_STATE.ANSWER_DONE && question.explanation && (
        <QuestionDescription
          dangerouslySetInnerHTML={{ __html: refineHTMLContent(question.explanation) }}
        />
      )}
      <br />
      {(testState === TEST_STATE.ANSWERING || testState === TEST_STATE.START) && (
        <Button onClick={answerQuestion}>Trả lời</Button>
      )}
      {testState === TEST_STATE.ANSWER_DONE && questionNumber < questions.length - 1 && (
        <Button onClick={nextQuestion}>Câu tiếp theo</Button>
      )}
      {testState === TEST_STATE.ANSWER_DONE && questionNumber === questions.length - 1 && (
        <Button onClick={viewResult}>Xem kết quả</Button>
      )}
      {testState === TEST_STATE.FINISHED && <Button onClick={viewResult}>Xem kết quả</Button>}
      {testState !== TEST_STATE.VIEW_RESULT && (
        <ProgressStatus>{`${questionNumber + 1}/${randomQuestions.length}`}</ProgressStatus>
      )}
      {testState === TEST_STATE.VIEW_RESULT && (
        <ChartWrapper>
          <p style={{ textAlign: 'center', marginTop: 10 }}>Số câu đúng:</p>
          <Progress
            percent={(resultAnswer.correct * 100) / randomQuestions.length}
            status="success"
            theme={{
              success: {
                symbol: `${resultAnswer.correct}/${randomQuestions.length}`,
                color: '#009f4d',
              },
            }}
          />
        </ChartWrapper>
      )}
      {testState === TEST_STATE.VIEW_RESULT && <Button onClick={reTest}>Làm lại</Button>}
    </Wrapper>
  );
}

QuestionTest.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      question: PropTypes.string,
      wrongAnswers: PropTypes.arrayOf(PropTypes.string),
      correctAnswers: PropTypes.arrayOf(PropTypes.string),
      explanation: PropTypes.string,
    })
  ),
};

QuestionTest.defaultProps = {
  questions: [],
};

export default QuestionTest;
